import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";

import dynamic from "next/dynamic"
import Image from "next/legacy/image";
import Link from "next/link";
import Box from "@mui/material/Box";
import { isSafari, productLink } from "@components/config/helpers";

const  IsDesignerOriginalImage = dynamic(() => import("./IsDesignerOriginalImage"), {
    ssr: false,
});

const CategoryImageContainer = (props) => {
    const{
        image,
        product_name,
        product_code,
        section_slug,
        product_slug,
        is_designer_original,
        product_idx,
        on_offer,
        long_term
    } = props;

    const show_title_product_code = useSelector((state) => state.globalReducer.template_settings.show_tile_product_code) || 'no';
    const red = useSelector((state) => state.globalReducer.template_settings.show_tile_product_code)
    const agent = useSelector((state) => state.globalReducer.agent);
    const lazy = product_idx < 8 ? false : true;

    return (
        // 
        <Box 
            className={`position-relative`} // newly added style due to adjustment in box-padding
            sx={{
                minHeight: '54px',
                // maxHeight:{lg:298},
                marginBottom: {
                    lg: '1rem',
                    md: '0rem',
                    xs: '0.5rem'
                },
            }}
        >
            { on_offer == 'yes' && <div className={'ribbon'}><span>ON OFFER</span></div>}
            <Link shallow={true} className="category_anchor position-relative"  href={productLink(section_slug, product_slug, long_term)}>
                <Image 
                    src={image} 
                    alt={product_name} 
                    title={product_name} 
                    layout="responsive" 
                    height={360}
                    width={360}
                    quality={100}
                    sizes="(max-width: 768px) 50vw, (max-width: 1200px) 20vw, 10vw"
                    priority={!lazy} // Set priority based on the 'lazy' variable
                    placeholder="blur" 
                    blurDataURL={process.env.NEXT_PUBLIC_BLUR_DATA_URL}
                />
                    {show_title_product_code == 'yes' && !isSafari(agent.user_agent) && (<Typography variant="span" component="span" className="code-on-image f-size_1">{product_code}</Typography>)}
            </Link>

            {(is_designer_original === "yes") && (
                 <Link shallow={true} href={productLink(section_slug, product_slug, long_term)}>
                     <IsDesignerOriginalImage/>
                 </Link>
            )}

        </Box>
    )
}

export default CategoryImageContainer